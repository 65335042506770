import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout/Layout"

import SEO from "../components/utils/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Fehler 404 – Seite nicht gefunden" />
    <h1>Fehler 404 – Seite nicht gefunden</h1>
    <p>Hoppla! Diese Seite existiert nicht!</p>
    <Link to="/">Zum Startseite!</Link>
  </Layout>
)

export default NotFoundPage
